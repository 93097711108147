import { zodResolver } from "@hookform/resolvers/zod";
import { type User } from "@roda/graphql/genql";
import {
  useContext,
  useMemo,
  useRef
} from "react";
import { useForm } from "react-hook-form";

import { Avatar } from "~/components/Avatar";
import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import { SelectInput } from "~/components/form";
import { FlywheelGoalOwnerSchema } from "~/components/form/formSchemas";
import { Icon } from "~/components/Icon";
import { VerticalDotMenuContext } from "~/components/VerticalDotMenu";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useUpdateFlywheelGoal } from "~/hooks/flywheel-goal";
import { useError } from "~/hooks/useError";

import type { z } from "zod";

interface EditFlywheelGoalOwnerMenuItemProps {
  openPopup: () => void;
  closePopup: () => void;
  isOpen: boolean;
}

export const EditFlywheelGoalOwnerMenuItem: React.FC<EditFlywheelGoalOwnerMenuItemProps> = ({
  openPopup, closePopup, isOpen
}) => {
  const { assertGraphQLSuccess, handleRodaError } = useError();
  const { flywheel } = useSelectedFlywheel();
  const flywheelGoal = flywheel?.latestFlywheelGoal;
  const { currentCompany } = useCurrentCompanyContext();
  const { closeVDotMenu } = useContext(VerticalDotMenuContext);
  const editFlywheelGoalOwnerContainerRef = useRef<HTMLDivElement>(null);
  const [ updateFlywheelGoalRes, updateFlywheelGoalReq ] = useUpdateFlywheelGoal();
  const activeCompanyUsers = useMemo(() => currentCompany ? currentCompany.activeUsers : [], [ currentCompany ]);

  // Edit flywheel goal owner form
  const {
    reset: resetFlywheelGoalOwnerForm,
    handleSubmit: handleSubmitFlywheelGoalOwnerForm,
    watch: watchFlywheelGoalOwnerForm,
    setValue: setFlywheelGoalOwnerFormValue,
    formState: { errors: flywheelGoalOwnerFormErrors }
  } = useForm<z.infer<typeof FlywheelGoalOwnerSchema>>({
    resolver: zodResolver(FlywheelGoalOwnerSchema),
    // Default to the current flywheel goal owner
    defaultValues: { ownerId: Number(flywheelGoal?.ownerId) || undefined },
    shouldFocusError: false
  });

  const watchedOwnerId = watchFlywheelGoalOwnerForm("ownerId");

  const handleUpdateFlywheelGoalOwner = handleSubmitFlywheelGoalOwnerForm(formValues => {
    // Update the flywheel goal owner
    if (flywheelGoal) {
      updateFlywheelGoalReq({
        flywheelGoalId: Number(flywheelGoal.id),
        ownerId: formValues.ownerId
      })
        .then(res => {
          assertGraphQLSuccess(res);
          closePopup();
          closeVDotMenu();
        })
        .catch(e => {
          handleRodaError(e, "Something went wrong. Please try again.");
        });
    }
  });

  const renderOptionLabel = (userId: string) => {
    const user = activeCompanyUsers?.find(user => user.id === userId);

    if (!user) return "User";

    return (
      <Avatar
        displayName
        px={30}
        user={user as User}
      />

    );
  };

  return (
    <>
      <button
        onClick={openPopup}
        className="leading-normal"
      >
        Change owner
      </button>

      {/** Edit flywheel goal owner popup */}
      <ConfirmationPopup
        isOpen={isOpen}
        title=""
        subtitle="Change flywheel goal owner"
        text="Who is responsible for reporting the flywheel goal?"
        ref={editFlywheelGoalOwnerContainerRef}
        continueText="Save"
        onContinue={handleUpdateFlywheelGoalOwner}
        onCancel={() => {
          if (!updateFlywheelGoalRes.fetching) { // Disable click outside to close the popup if the request is still fetching
            resetFlywheelGoalOwnerForm();
            closePopup();
            closeVDotMenu();
          }
        }}
        loading={updateFlywheelGoalRes.fetching}
        continueDisabled={updateFlywheelGoalRes.fetching}
      >
        <form
          onSubmit={handleUpdateFlywheelGoalOwner}
          className="flex flex-col flex-1 gap-4"
        >
          <SelectInput
            placeholder="Choose a flywheel goal owner"
            value={String(watchedOwnerId)}
            onValueChange={val => setFlywheelGoalOwnerFormValue("ownerId", Number(val))}
            options={activeCompanyUsers ? activeCompanyUsers?.map(user => String(user.id)) : []}
            renderOptionLabel={renderOptionLabel}
            errorMessage={flywheelGoalOwnerFormErrors.ownerId?.message}
          />

          <div className="flex flex-row gap-1 items-center text-xs text-brand-cold-metal-400">
            <Icon.InfoCircle className="min-w-[14px]" />

            <p>Your flywheel goal owner must be an admin</p>
          </div>
        </form>
      </ConfirmationPopup>
    </>
  );
};