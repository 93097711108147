import { zodResolver } from "@hookform/resolvers/zod";
import { enumUserCompanyRole } from "@roda/graphql/genql";
import {
  useCallback,
  useEffect,
  useState
} from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  useLocation,
  useNavigate
} from "react-router-dom";

import { Avatar } from "~/components/Avatar";
import { Breadcrumbs } from "~/components/Breadcrumbs";
import { Button } from "~/components/Button";
import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import { Divider } from "~/components/Divider";
import { EditMemberSchema } from "~/components/form/formSchemas";
import { Input } from "~/components/form/Input";
import { SelectInput } from "~/components/form/SelectInput";
import { Icon } from "~/components/Icon";
import { Spacer } from "~/components/Spacer";
import { routes } from "~/constants/routes";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useUpdateUser } from "~/hooks/user";
import { useDeleteUserfromCompany } from "~/hooks/user/use-delete-user-from-company";
import type { RoleLabelKey } from "~/pages/Members";
import { roleLabels } from "~/pages/Members";

import type { User as UserType } from "@roda/graphql/genql";

interface UserData {
  firstName: UserType["firstName"];
  lastName: UserType["lastName"];
  email: UserType["email"];
  jobTitle: UserType["jobTitle"];
  role: UserType["role"]
  id: UserType["id"];
  verified: UserType["verified"];
  avatarS3Key?: UserType["avatarS3Key"];
}

export const EditMember = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [ userData, setUserData ] = useState <UserData | null>(state ? state.userData : null);
  const { user: currentUser } = useCurrentUser();
  const isViewingSelf = userData?.id === currentUser?.id;
  const { setHideMainSideNav } = useSideNavigation();
  const { refetchFlywheel } = useSelectedFlywheel();
  const [ showDeleteUserFromCompanyConfirmationPopup, setShowDeleteUserFromCompanyConfirmationPopup ] = useState(false);
  const { currentCompany } = useCurrentCompanyContext();

  // On render of this page - always show the main side nav
  useEffect(() => {
    setHideMainSideNav(false);
  }, [ setHideMainSideNav ]);

  // navigate to members page if no user data is passed
  useEffect(() => {
    if (!userData) {
      navigate(routes.members(currentCompany?.id));
    }
  }, [
    userData,
    navigate,
    currentCompany?.id
  ]);

  const [ updateUserRes, updateUserReq ] = useUpdateUser();
  const [ deleteFromCompanyRes, deleteFromCompanyReq ] = useDeleteUserfromCompany();
  const isMobile = useIsMobile();
  // State for managing update status and email verification modal visibility
  const { handleRodaError, assertGraphQLSuccess } = useError();

  // Form hook for managing form state and interactions
  const {
    handleSubmit,
    reset,
    register,
    clearErrors,
    getValues,
    setValue,
    formState: { errors, isDirty }
  } = useForm({
    resolver: zodResolver(EditMemberSchema),
    defaultValues: {
      // Set initial form values with user details
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
      jobTitle: userData?.jobTitle,
      role: userData?.role
    },
    shouldFocusError: false
  });

  // Function for handling form submission
  const submitHandler = handleSubmit(async formData => {
    if (!currentCompany?.id) {
      toast.error("You must be part of an organisation to edit a user");

      return;
    }

    // Update user details via API request
    const dataToSend = {
      role: formData.role,
      userId: parseInt(userData!.id),
      // Using the company id of the user who is logged in and making the changes as they are the admin of said company
      companyId: +currentCompany?.id
    };

    const response = await updateUserReq(dataToSend);

    if (response.data) {
      toast.success("Details updated successfully");

      // Update local state with new user details
      reset({
        role: response.data.updateUser.role,
        firstName: response.data.updateUser.firstName,
        lastName: response.data.updateUser.lastName,
        jobTitle: response.data.updateUser.jobTitle,
        email: response.data.updateUser.email
      });
      const updatedUser = response.data.updateUser;

      setUserData({
        firstName: updatedUser.firstName,
        lastName: updatedUser.lastName,
        jobTitle: updatedUser.jobTitle,
        email: updatedUser.email,
        role: updatedUser.role,
        id: updatedUser.id,
        verified: updatedUser.verified
      });
    } else if (response.error) {
      handleRodaError(response.error, "Failed to update user details");
    }
  }
  );

  const handleDeleteFromCompany = useCallback(() => {
    if (userData?.id && currentCompany?.id) {
      deleteFromCompanyReq({
        userId: userData?.id,
        companyId: +currentCompany?.id
      }).then(res => {
        assertGraphQLSuccess(res);
        refetchFlywheel();
        navigate(routes.members(currentCompany?.id));
      }).catch(err => {
        console.error(err);
        toast.error("There was an unexpected error.");
      });
    } else {
      toast.error("You must be part of an organisation to remove a user");
    }
  }, [
    refetchFlywheel,
    assertGraphQLSuccess,
    currentCompany?.id,
    deleteFromCompanyReq,
    navigate,
    userData?.id
  ]);

  return (
    <div className={`flex flex-col w-full flex-1 bg-white ${!isMobile && "px-10"}`}>
      {/* Breadcrumbs */}
      {!isMobile && (
        <Breadcrumbs
          crumbs={[
            {
              label: "Members",
              to: routes.members(currentCompany?.id)
            },
            { label: `${userData?.firstName && userData?.lastName ? userData.firstName + " " + userData.lastName : userData ? userData?.email : ""}` }
          ]}
        />
      )}

      <div className="flex flex-col items-center justify-start w-full flex-1">

        {/* Container for form */}
        <div className={`max-w-[900px] flex-1 w-full text-xs sm:text-sm flex flex-col px-5 ${isMobile ? "mt-4 gap-4" : "mt-10 gap-8"}`}>
          <form
            onSubmit={submitHandler}
            className="flex-1 flex flex-col"
          >
            <div className={`flex justify-between ${isMobile ? "flex-col gap-4 pb-4" : "pb-8"}`}>
              {/* User avatar & info */}
              <div className="flex items-center flex-1">
                {userData && (
                  <Avatar
                    user={userData}
                    px={isMobile ? 40 : 50}
                  />
                )}

                <div className="flex flex-col pl-2">

                  {userData?.firstName && userData.lastName && (
                    <div className={`text-brand-cold-metal-900 font-semibold ${isMobile ? "text-base" : "text-lg"}`}>
                      {userData.firstName + " " + userData.lastName}
                    </div>
                  )}

                  <div className="text-brand-cold-metal-500">{userData?.email} </div>
                </div>

              </div>

              <div>
                <SelectInput
                  {...register("role")}
                  options={[ enumUserCompanyRole.ADMIN, enumUserCompanyRole.USER ]}
                  label="Membership role"
                  placeholder="Select a role"
                  onValueChange={value => setValue("role", value, { shouldDirty: true })}
                  clearFormError={() => clearErrors("role")}
                  hasError={!!errors.role}
                  errorMessage={errors.role?.message}
                  value={getValues("role")!}
                  renderOptionLabel={option => roleLabels[ option as RoleLabelKey ]}
                  className={`${isMobile ? "w-full" : "max-w-[150px] min-w-[150px]"}`}
                />
              </div>
            </div>

            <Divider />

            <div className={`flex flex-row mb-6 gap-5  ${isMobile ? "flex-col pt-4" : "pt-8"}`}>
              {/* First Name */}
              <div className="flex-1">
                <Input
                  label="First Name"
                  {...register("firstName")}
                  hasError={!!errors.firstName}
                  errorMessage={errors.firstName?.message}
                  disabled={updateUserRes.fetching || !isViewingSelf}
                  inputMode="text"
                />
              </div>

              {/* Last Name */}
              <div className="flex-1">
                <Input
                  label="Last Name"
                  {...register("lastName")}
                  hasError={!!errors.lastName}
                  errorMessage={errors.lastName?.message}
                  disabled={updateUserRes.fetching || !isViewingSelf}
                  inputMode="text"
                />
              </div>
            </div>

            <div className="gap-5 flex flex-col mb-6">
              {/* Email */}
              <Input
                label="Work email"
                {...register("email")}
                hasError={!!errors.email}
                errorMessage={errors.email?.message as string}
                disabled
                inputMode="email"
                subText={userData?.verified ? "This email is verified" : ""}
                iconComponent={userData?.verified ? (
                  <Icon.Verified
                    className="text-[#2BB762]"
                    size={20}
                  />
                ) : undefined}
              />

              {/* Job Title */}
              <Input
                label="Job Title"
                {...register("jobTitle")}
                hasError={!!errors.jobTitle}
                errorMessage={errors.jobTitle?.message as string}
                disabled={updateUserRes.fetching || !isViewingSelf}
                inputMode="text"
              />
            </div>

            {isMobile && (<Spacer />)}

            {/* Action buttons for saving or discarding changes */}
            {isDirty && (
              <div className={`flex gap-x-3 self-start ${isMobile ? "flex-col w-full gap-3 pb-5" : "flex-row"}`}>
                <Button
                  type="submit"
                  disabled={updateUserRes.fetching || Object.keys(errors).length > 0}
                  loading={updateUserRes.fetching}
                  title="Save"
                  className={`px-6 ${isMobile ? "order-first" : ""}`}
                />

                <Button
                  type="button"
                  onClick={() => reset()}
                  title="Discard"
                  disabled={updateUserRes.fetching}
                  className="bg-brand-cold-metal-100 text-brand-cold-metal-900 hover:contrast-75"
                />

              </div>
            )}

            {!isViewingSelf && currentUser?.role === enumUserCompanyRole.ADMIN && (

              <div className="mt-4">
                <Button
                  type="button"
                  onClick={() => setShowDeleteUserFromCompanyConfirmationPopup(true)}
                  title="Remove from organisation"
                  className=" bg-brand-error-red-400 text-brand-cold-metal-900 hover:contrast-75"
                />
              </div>
            )}

          </form>
        </div>
      </div>

      <ConfirmationPopup
        isOpen={showDeleteUserFromCompanyConfirmationPopup}
        onContinue={handleDeleteFromCompany}
        onCancel={() => setShowDeleteUserFromCompanyConfirmationPopup(false)}
        loading={deleteFromCompanyRes.fetching}
        title="Are you sure?"
        text={`Any metrics that ${userData?.firstName || userData?.email} owns will be assigned to you.`}
        iconComponent={(
          <Icon.Bin
            size={28}
            className="text-brand-cold-metal-700"
          />
        )}
      />

    </div>
  );
};
