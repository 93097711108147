import { useTypedQuery } from "@roda/graphql/urql";

import { ReadOnlyUser } from "~/fragments/index";

type UseTypedQueryOpts = Omit<Parameters<typeof useTypedQuery>[0], "query">;

export type GetCompanyResponse = NonNullable<NonNullable<ReturnType<typeof useGetCompany>["0"]["data"]>["getCompany"]>;

export const useGetCompany = (companyId: number, opts?: UseTypedQueryOpts) => {
  return useTypedQuery({
    ...opts,
    query: {
      getCompany: {
        __args: { id: companyId },
        id: true,
        name: true,
        createdAt: true,
        updatedAt: true,
        industryId: true,
        stripeSubscriptionRef: true,
        paymentPrompt: true,
        trialExpiryAt: true,
        stripeCustomerRef: true,
        stripeStatus: true,
        completedOnboarding: true,
        logoS3Key: true,
        logoS3Bucket: true,
        activeUserCount: true,
        invitedUserCount: true,
        flywheels: {
          id: true,
          setupComplete: true,
          companyId: true,
          deletedAt: true,
          name: true,
          latestFlywheelGoal: {
            id: true,
            achieveBy: true,
            ownerId: true,
            createdBy: true,
            goal: true,
            cap: true,
            name: true,
            unitName: true,
            unitType: true,
            unitTypeLabel: true,
            unitDisplay: true,
            unitDescription: true,
            updateFrequency: true,
            subgoals: {
              id: true,
              endDate: true,
              createdAt: true,
              updatedAt: true,
              __typename: true,
              startDate: true,
              goal: true,
              flywheelGoalId: true,
              latestUpdate: {
                id: true,
                flywheelGoalId: true,
                startDate: true,
                value: true,
                notes: true,
                isHealthy: true,
                updatedAt: true
              },

              isHealthy: true,
              isCheckInDue: true,
              latestProgress: true
            },
            latestTotalValue: true,
            checkInSubgoal: {
              id: true,
              endDate: true,
              startDate: true,
              createdAt: true,
              updatedAt: true,
              __typename: true,
              goal: true,
              flywheelGoalId: true,
              latestUpdate: {
                id: true,
                flywheelGoalId: true,
                startDate: true,
                value: true,
                notes: true,
                isHealthy: true,
                subgoalId: true,
                updatedAt: true,
                createdAt: true,
                __typename: true
              },

              isHealthy: true,
              isCheckInDue: true,
              latestProgress: true
            },
            isHealthy: true,
            fromDate: true,
            toDate: true
          },
          steps: {
            id: true,
            name: true,
            order: true,
            ownerId: true,
            alias: true
          }
        },
        activeUsers: {
          ...ReadOnlyUser,
          lastSeenAt: true
        },
        counts: {
          metricsCheckedInCount: true,
          metricsTotalCount: true,
          flywheelCount: true
        }
      }
    }
  });
};