import { type Flywheel } from "@roda/graphql/genql";
import { UserRole } from "@roda/shared/types";
import clsx from "clsx";
import { useEffect } from "react";
import {
  useNavigate,
  useParams
} from "react-router-dom";
import defaultTheme from "tailwindcss/defaultTheme";

import SetupCompleteImg from "~/assets/illustrations/setup.png";
import { Button } from "~/components/Button";
import { FlywheelInnerContent } from "~/components/flywheel/roda/FlywheelInnerContent";
import { FlywheelOutlet } from "~/components/flywheel/roda/FlywheelOutlet";
import {
  RodaFlywheel,
  withStepTheme
} from "~/components/flywheel/roda/RodaFlywheel";
import { StatusBubble } from "~/components/flywheel/roda/StatusBubble";
import { HealthStatus } from "~/components/flywheel/roda/types";
import { Icon } from "~/components/Icon";
import { SaveImageButton } from "~/components/SaveImageButton";
import { Loading } from "~/components/Spinner";
import { CurrentDatePicker } from "~/components/time-travel/CurrentDatePicker";
import { routes } from "~/constants/routes";
import { useAuth } from "~/contexts/AuthContext";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useCustomiseFlywheelGoalDispatch } from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";
import { useCustomiseMetricDispatch } from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import { useDynamicResize } from "~/contexts/DynamicSizeContext";
import { useFlywheelLoader } from "~/contexts/FlywheelLoaderContext";
import { ImageCaptureTarget } from "~/contexts/ImageCaptureContext";
import type { StepType } from "~/contexts/OnboardingContext/onboarding-reducer";
import { useOnboardingDispatch } from "~/contexts/OnboardingContext/OnboardingContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useRodaSubscription } from "~/contexts/SubscriptionContext";
import { useCurrentUser } from "~/contexts/UserContext";
import type { GetCompanyResponse } from "~/hooks/company/use-get-company";
import { useViewport } from "~/hooks/useIsMobile";
import { cn } from "~/utils/cn";
import { getHealthStatusColour } from "~/utils/getHealthStatusColour";
import { getStepStatus } from "~/utils/getStepStatus";
import { getUnitSymbol } from "~/utils/getUnitSymbol";

const getStepFromFlywheel = (flywheel: Flywheel, stepId: StepType["id"]) => {
  return flywheel?.steps?.find(step => step?.id === stepId);
};

export const FlywheelContainer = () => {
  const params = useParams() as { stepId: string, metricId?: string };
  const isGoal = window.location.href.includes("goal");
  const navigate = useNavigate();
  const { user: currentUser } = useCurrentUser();
  const { loading } = useAuth();
  const { setHideMainSideNav } = useSideNavigation();
  const { ready } = useDynamicResize();
  const { draftFlywheels } = useFlywheelLoader();
  const customiseMetricDispatch = useCustomiseMetricDispatch();
  const onboardingDispatch = useOnboardingDispatch();
  const customiseFlywheelGoalDispatch = useCustomiseFlywheelGoalDispatch();
  const { activeOrTrialing } = useRodaSubscription();
  const width = useViewport();
  const { screens } = defaultTheme;
  const isMobileFlywheelBreakpoint = width < parseInt(screens.sm);

  const {
    flywheel, flywheelSubgoals, flywheelCycleNotStarted, isFullyLoaded, setActiveFlywheelId
  } = useSelectedFlywheel();

  // Flywheel display
  const minFlywheelSizeDesktop = 300;
  const minFlywheelSizeMobile = 220;
  const flywheelSize = isMobileFlywheelBreakpoint ? Math.max(minFlywheelSizeMobile, Math.min(window.innerWidth * 0.60, 500)) : Math.max(minFlywheelSizeDesktop, window.innerWidth / 3.5);
  const { currentCompany, setCurrentCompany } = useCurrentCompanyContext();
  const step = flywheel ? getStepFromFlywheel(flywheel, params.stepId) : null;

  useEffect(() => {
    // Re-direct to onboarding if
    // user doesn't have a companyId yet (not for Roda admins)
    // user has a company but they haven't completed onboarding
    if ((currentUser && !currentCompany?.id && currentUser?.role !== UserRole.RODA_ADMIN) || (currentUser && currentCompany && !currentCompany.completedOnboarding)) {
      if (!currentUser.userCompanies?.length) {
        navigate(routes.onboarding({ newCompany: true }));
      }
    }
  }, [
    currentUser,
    navigate,
    currentCompany
  ]);

  // On render of this page - always show the main side nav
  useEffect(() => {
    setHideMainSideNav(false);
  }, [ setHideMainSideNav ]);

  // Flywheel animation classnames - to get intellisense working I had to name
  // it simply as 'classname'.
  // Need to declare them outside the JSX as they need to be removed for
  // image-capture otherwise they aren't captured
  const classname = "animate-in fade-in-0 fill-mode-both delay-500 duration-500";

  return (
    <div className="">
      <div className="flex flex-col overflow-hidden w-full h-container items-center justify-between">

        {import.meta.env.VITE_STAGE !== "production" && (
          <div
            id="dev_box"
            className={`justify-end absolute top-0 desktop:top-14 z-10 right-0 ${(!step && !isGoal) ? "flex" : "hidden"}`}
          >
            <CurrentDatePicker />
          </div>
        )}

        {ready && (
          <>

            {!flywheel ? (
              <div className="flex flex-col relative h-flywheel-container w-full z-10">
                <div className="absolute absolute-center flex flex-col gap-4 items-center">
                  <h4 className="text-lg font-semibold text-center">There's nothing here yet</h4>

                  <p className="text-sm text-center">It doesn't look like there are any active flywheels for this company.</p>

                  {draftFlywheels?.length ? (
                    <Button
                      title="Continue setup"
                      onClick={() => {
                        customiseMetricDispatch({ type: "RESET" });
                        onboardingDispatch({ type: "RESET" });
                        customiseFlywheelGoalDispatch({ type: "RESET" });
                        setActiveFlywheelId(draftFlywheels?.[ 0 ]?.id);
                        navigate(routes.onboarding({ newCompany: false }));
                      }}
                    />
                  ) : (
                    <Button
                      title="Start flywheel setup"
                      onClick={() => {
                        customiseMetricDispatch({ type: "RESET" });
                        onboardingDispatch({ type: "RESET" });
                        customiseFlywheelGoalDispatch({ type: "RESET" });
                        setActiveFlywheelId(null);
                        navigate(routes.onboarding({ newCompany: false }));
                      }}
                    />

                  )}

                </div>
              </div>
            ) : (
              <>

                {(!flywheel?.steps?.length) ? (
                  <div className="flex flex-col relative h-flywheel-container w-full z-10">
                    <div className="absolute absolute-center">
                      <Loading.Spinner
                        size={24}
                        color="gray"
                        containerProps={{ className: "mx-1" }}
                      />
                    </div>
                  </div>
                ) : (

                  <section className="flex flex-col h-flywheel-container w-full">
                    <ImageCaptureTarget captureId="flywheel">

                      {!flywheel?.latestFlywheelGoal ? ( // If no goal it means it's the first week of the first cycle!
                        <div
                          className="flex flex-col -top-16 w-full h-full absolute absolute-center items-center gap-6 justify-center"
                          style={withStepTheme(getHealthStatusColour(HealthStatus.Waiting))}
                        >
                          <img
                            src={SetupCompleteImg}
                            className="select-none w-48 h-48  "
                          />

                          <div className="flex p-2 flex-col justify-center text-center items-center gap-3">
                            <div>
                              <StatusBubble status={HealthStatus.Waiting} />
                            </div>

                            <h3 className="text-lg font-semibold text-center text-balance">
                              Your first flywheel cycle starts soon.
                            </h3>

                            <p className="text-sm">
                              We'll notify you when it's time to check-in.
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={clsx("h-screen pt-10", !activeOrTrialing && "[&_*]:cursor-default", flywheel?.steps && !loading ? classname : "opacity-0", isMobileFlywheelBreakpoint ? "max-w-[100vw]" : " overflow-visible")}
                        >
                          {flywheel?.steps && (
                            <RodaFlywheel
                              key={flywheel.id}
                              flywheel={{ steps: flywheel.steps }}
                              size={flywheelSize}
                              shouldHoverSteps={!step && !isGoal && !flywheelCycleNotStarted}
                              selectedStepIdx={flywheel.steps.findIndex(s => s.id === step?.id)}
                              position={step ? "side" : isGoal ? "huge" : "centre"}
                              loading={loading}
                              onStepClick={step => {
                                if (!activeOrTrialing) {
                                  return null;
                                }

                                navigate(routes.step(step.id!, currentCompany ? currentCompany.id : undefined));
                              }}
                              stepsProps={flywheel.steps?.map(step => {
                                // Waiting by default
                                const status: HealthStatus = (flywheelCycleNotStarted || !isFullyLoaded) ? HealthStatus.Waiting : getStepStatus(step);

                                return {
                                  stepContainerStyle: withStepTheme(getHealthStatusColour(status)),
                                  children: (
                                    isMobileFlywheelBreakpoint ? (<></>) : null
                                  )
                                };
                              })}
                              renderInnerContent={() => {
                                // Pick the current checkInSubgoal. If there isn't one, it means we are in the first month of the first cycle- so we'll just show the first quarter subgoal:
                                const currentQuarter = flywheel.latestFlywheelGoal?.checkInSubgoal || flywheel.latestFlywheelGoal?.subgoals?.[ 0 ];
                                const currentQuarterIdx = flywheelSubgoals?.findIndex(s => s.id === currentQuarter?.id);

                                // We check this above but we'll render null for type safety
                                if (!flywheel.latestFlywheelGoal) {
                                  return (<></>);
                                }

                                return (
                                  <FlywheelInnerContent
                                    enabled={!step && !isGoal}
                                    goalName={flywheel.latestFlywheelGoal.name}
                                    quarter={`Q${currentQuarterIdx !== -1 ? (currentQuarterIdx || 0) + 1 : 1}`}
                                    quarterProgress={(currentQuarter?.latestProgress || 0).toString()}
                                    quarterTotal={currentQuarter?.goal || "0"}
                                    mainGoalProgress={flywheel.latestFlywheelGoal.latestTotalValue}
                                    mainGoalTotal={flywheel.latestFlywheelGoal.goal}
                                    mainGoalHealthy={flywheel.latestFlywheelGoal.isHealthy !== null ? flywheel.latestFlywheelGoal.isHealthy : false}
                                    unitIcon={getUnitSymbol(flywheel.latestFlywheelGoal?.unitTypeLabel, flywheel.currency)}
                                  />

                                );
                              }}
                            />
                          )}

                        </div>
                      )}
                    </ImageCaptureTarget>

                  </section>
                )}

              </>
            )}

          </>
        )}

        <div
          id="flywheel-footer"
          style={{
            opacity: (!step && !isGoal) ? 1 : 0,
            transition: `opacity 800ms cubic-bezier(0.7, 0.18, 0.06, 1) ${(!step && !isGoal) ? "1s" : "0s"}`
          }}
          className="flex mt-auto justify-center flex-col items-center w-full pb-5"
        >
          <p className="text-xl font-semibold flex-1 w-[80%] max-w-[350px] text-center mb-1 text-balance break-words">{flywheel?.name}</p>

          <div className="lg:hidden w-full px-8 pt-5">
            {!!flywheel?.steps?.length && (
              <Button
                title="Explore steps"
                iconComponent={<Icon.BarChart />}
                className="text-brand-cold-metal-200 flex-1 w-full bg-brand-cold-metal-800 font-normal py-3 px-6 text-sm"
                onClick={() => navigate(routes.step(flywheel.steps![ 0 ].id!, currentCompany ? currentCompany.id : undefined))}
              />
            )}
          </div>
        </div>

        {flywheel?.latestFlywheelGoal && (
          <SaveImageButton
            captureId="flywheel"
            html2canvasOptions={{
              onclone(doc) {
                // annoyingly the text wraps in the image capture, so we need to force nowrap
                doc.querySelectorAll("[data-status-bubble]")
                  .forEach(el => {
                    (el as HTMLElement).style.setProperty("white-space", "nowrap");
                  });

                // ^ and the same here
                doc.querySelectorAll("[id='flywheel-inner-content'] *")
                  .forEach(el => {
                    (el as HTMLElement).style.setProperty("white-space", "nowrap");
                  });
              },
              // for this SVG-heavy capture-subject, we need to use the
              // foreignObject rendering for the most accurate results
              foreignObjectRendering: true,
              // the side-nav seemingly affects the layout of this capture, to
              // have to nudge it backward the X-width of the side-nav
              x: -1 * (document.querySelector("aside")?.getBoundingClientRect().width ?? 0)
            }}
            className={cn("mobile:hidden absolute top-4 right-4", { hidden: step || isGoal })}
          />
        )}
      </div>

      {(step || isGoal) && (
        <FlywheelOutlet
          stepId={step?.id}
          showStepNav={!!step}
        />
      )}
    </div>
  );
};
