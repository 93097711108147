export default {
    "scalars": [
        1,
        2,
        3,
        6,
        8,
        10,
        13,
        18,
        30,
        31,
        35
    ],
    "types": {
        "Company": {
            "activeUserCount": [
                1
            ],
            "activeUsers": [
                33
            ],
            "completedOnboarding": [
                1
            ],
            "counts": [
                4
            ],
            "createdAt": [
                10
            ],
            "flywheels": [
                11
            ],
            "id": [
                2
            ],
            "industry": [
                17
            ],
            "industryId": [
                1
            ],
            "invitedUserCount": [
                1
            ],
            "lastSeenUser": [
                33
            ],
            "logoS3Bucket": [
                3
            ],
            "logoS3Key": [
                3
            ],
            "name": [
                3
            ],
            "paymentPrompt": [
                1
            ],
            "stripeCustomerRef": [
                3
            ],
            "stripeStatus": [
                3
            ],
            "stripeSubscriptionRef": [
                3
            ],
            "trialExpiryAt": [
                10
            ],
            "updatedAt": [
                10
            ],
            "userCompany": [
                34
            ],
            "__typename": [
                3
            ]
        },
        "Int": {},
        "ID": {},
        "String": {},
        "CountType": {
            "flywheelCount": [
                1
            ],
            "metricsCheckedInCount": [
                1
            ],
            "metricsTotalCount": [
                1
            ],
            "__typename": [
                3
            ]
        },
        "CreateMetricWithTargetArgs": {
            "cap": [
                6
            ],
            "fromDate": [
                3
            ],
            "name": [
                3
            ],
            "ownerEmail": [
                3
            ],
            "reportingWindowTiming": [
                3
            ],
            "slug": [
                3
            ],
            "stepId": [
                1
            ],
            "target": [
                3
            ],
            "toDate": [
                3
            ],
            "unitDescription": [
                3
            ],
            "unitDisplay": [
                3
            ],
            "unitName": [
                3
            ],
            "unitTargetType": [
                3
            ],
            "unitType": [
                3
            ],
            "unitTypeLabel": [
                3
            ],
            "__typename": [
                3
            ]
        },
        "Float": {},
        "CreateSubgoalArgs": {
            "endDate": [
                3
            ],
            "goal": [
                3
            ],
            "startDate": [
                3
            ],
            "__typename": [
                3
            ]
        },
        "Currency": {},
        "CurrentDate": {
            "date": [
                10
            ],
            "userId": [
                1
            ],
            "__typename": [
                3
            ]
        },
        "DatetimeString": {},
        "Flywheel": {
            "companyId": [
                1
            ],
            "createdAt": [
                10
            ],
            "currency": [
                3
            ],
            "deletedAt": [
                10
            ],
            "flywheelTemplateId": [
                1
            ],
            "id": [
                2
            ],
            "latestFlywheelGoal": [
                12
            ],
            "name": [
                3
            ],
            "ownerId": [
                1
            ],
            "setupComplete": [
                1
            ],
            "steps": [
                27
            ],
            "timeframe": [
                3
            ],
            "updateFrequency": [
                3
            ],
            "updatedAt": [
                10
            ],
            "__typename": [
                3
            ]
        },
        "FlywheelGoal": {
            "achieveBy": [
                10
            ],
            "cap": [
                6
            ],
            "checkInSubgoal": [
                29
            ],
            "createdAt": [
                10
            ],
            "createdBy": [
                1
            ],
            "flywheelId": [
                1
            ],
            "fromDate": [
                10
            ],
            "goal": [
                3
            ],
            "id": [
                2
            ],
            "isHealthy": [
                13
            ],
            "latestTotalValue": [
                6
            ],
            "name": [
                3
            ],
            "owner": [
                33
            ],
            "ownerId": [
                1
            ],
            "subgoals": [
                29
            ],
            "toDate": [
                10
            ],
            "unitDescription": [
                3
            ],
            "unitDisplay": [
                3
            ],
            "unitName": [
                3
            ],
            "unitType": [
                3
            ],
            "unitTypeLabel": [
                3
            ],
            "updateFrequency": [
                3
            ],
            "updatedAt": [
                10
            ],
            "__typename": [
                3
            ]
        },
        "Boolean": {},
        "FlywheelGoalUpdate": {
            "createdAt": [
                10
            ],
            "flywheelGoalId": [
                1
            ],
            "id": [
                2
            ],
            "isHealthy": [
                13
            ],
            "notes": [
                3
            ],
            "startDate": [
                10
            ],
            "subgoalId": [
                1
            ],
            "updatedAt": [
                10
            ],
            "updatedBy": [
                33
            ],
            "value": [
                3
            ],
            "__typename": [
                3
            ]
        },
        "FlywheelGoalUpdateArgs": {
            "flywheelGoalId": [
                1
            ],
            "flywheelGoalUpdateId": [
                1
            ],
            "startDate": [
                3
            ],
            "subgoalId": [
                1
            ],
            "value": [
                3
            ],
            "__typename": [
                3
            ]
        },
        "FlywheelTemplate": {
            "createdAt": [
                10
            ],
            "id": [
                2
            ],
            "info": [
                3
            ],
            "name": [
                3
            ],
            "updatedAt": [
                10
            ],
            "__typename": [
                3
            ]
        },
        "Industry": {
            "id": [
                2
            ],
            "name": [
                3
            ],
            "__typename": [
                3
            ]
        },
        "JSON": {},
        "Metric": {
            "cap": [
                6
            ],
            "createdAt": [
                10
            ],
            "fromDate": [
                10
            ],
            "id": [
                2
            ],
            "isCheckInDue": [
                13
            ],
            "isHealthy": [
                13
            ],
            "isReviewDue": [
                13
            ],
            "metricUpdates": [
                21
            ],
            "name": [
                3
            ],
            "owner": [
                33
            ],
            "reportingWindowTiming": [
                3
            ],
            "reviewers": [
                36
            ],
            "slug": [
                3
            ],
            "stepId": [
                1
            ],
            "targets": [
                20
            ],
            "toDate": [
                10
            ],
            "unitDescription": [
                3
            ],
            "unitDisplay": [
                3
            ],
            "unitName": [
                3
            ],
            "unitTargetType": [
                3
            ],
            "unitType": [
                3
            ],
            "unitTypeLabel": [
                3
            ],
            "updatedAt": [
                10
            ],
            "__typename": [
                3
            ]
        },
        "MetricTarget": {
            "createdAt": [
                10
            ],
            "createdBy": [
                33
            ],
            "fromDate": [
                10
            ],
            "id": [
                2
            ],
            "isCurrent": [
                13
            ],
            "isNext": [
                13
            ],
            "metricId": [
                1
            ],
            "target": [
                3
            ],
            "toDate": [
                10
            ],
            "updatedAt": [
                10
            ],
            "__typename": [
                3
            ]
        },
        "MetricUpdate": {
            "createdAt": [
                10
            ],
            "id": [
                2
            ],
            "isHealthy": [
                13
            ],
            "metricId": [
                1
            ],
            "metricTarget": [
                20
            ],
            "metricTargetId": [
                1
            ],
            "notes": [
                3
            ],
            "startDate": [
                10
            ],
            "updatedAt": [
                10
            ],
            "updatedBy": [
                33
            ],
            "value": [
                3
            ],
            "__typename": [
                3
            ]
        },
        "MetricWithoutStepIdArgs": {
            "cap": [
                6
            ],
            "fromDate": [
                3
            ],
            "name": [
                3
            ],
            "ownerEmail": [
                3
            ],
            "reportingWindowTiming": [
                3
            ],
            "slug": [
                3
            ],
            "target": [
                3
            ],
            "toDate": [
                3
            ],
            "unitDescription": [
                3
            ],
            "unitDisplay": [
                3
            ],
            "unitName": [
                3
            ],
            "unitTargetType": [
                3
            ],
            "unitType": [
                3
            ],
            "unitTypeLabel": [
                3
            ],
            "__typename": [
                3
            ]
        },
        "Mutation": {
            "batchCreate": [
                13,
                {
                    "completedOnboarding": [
                        1
                    ],
                    "metrics": [
                        5,
                        "[CreateMetricWithTargetArgs!]!"
                    ]
                }
            ],
            "bulkUpdateSubgoalsAndProgress": [
                29,
                {
                    "flywheelGoalId": [
                        1,
                        "Int!"
                    ],
                    "progressUpdates": [
                        15,
                        "[FlywheelGoalUpdateArgs!]!"
                    ],
                    "subgoalGoalValue": [
                        3
                    ]
                }
            ],
            "createCompany": [
                0,
                {
                    "industryId": [
                        1,
                        "Int!"
                    ],
                    "name": [
                        3,
                        "String!"
                    ]
                }
            ],
            "createFlywheelGoalAndSubgoals": [
                12,
                {
                    "achieveBy": [
                        3,
                        "String!"
                    ],
                    "cap": [
                        6
                    ],
                    "flywheelGoalId": [
                        1
                    ],
                    "flywheelId": [
                        1,
                        "Int!"
                    ],
                    "fromDate": [
                        3,
                        "String!"
                    ],
                    "goal": [
                        3,
                        "String!"
                    ],
                    "name": [
                        3,
                        "String!"
                    ],
                    "ownerEmail": [
                        3,
                        "String!"
                    ],
                    "steps": [
                        28,
                        "[StepWithMetricsArgs!]"
                    ],
                    "subgoals": [
                        7,
                        "[CreateSubgoalArgs!]!"
                    ],
                    "toDate": [
                        3
                    ],
                    "unitDescription": [
                        3,
                        "String!"
                    ],
                    "unitDisplay": [
                        3,
                        "String!"
                    ],
                    "unitName": [
                        3,
                        "String!"
                    ],
                    "unitType": [
                        3,
                        "String!"
                    ],
                    "unitTypeLabel": [
                        3,
                        "String!"
                    ],
                    "updateFrequency": [
                        31,
                        "UpdateFrequency!"
                    ]
                }
            ],
            "createFlywheelTemplate": [
                16,
                {
                    "info": [
                        3,
                        "String!"
                    ],
                    "name": [
                        3,
                        "String!"
                    ]
                }
            ],
            "createIndustry": [
                17,
                {
                    "name": [
                        3,
                        "String!"
                    ]
                }
            ],
            "createMetricTarget": [
                20,
                {
                    "createdBy": [
                        1,
                        "Int!"
                    ],
                    "fromDate": [
                        3,
                        "String!"
                    ],
                    "metricId": [
                        1,
                        "Int!"
                    ],
                    "target": [
                        3,
                        "String!"
                    ]
                }
            ],
            "createMetricUpdate": [
                19,
                {
                    "metricId": [
                        1,
                        "Int!"
                    ],
                    "metricTargetId": [
                        1,
                        "Int!"
                    ],
                    "notes": [
                        3
                    ],
                    "startDate": [
                        3,
                        "String!"
                    ],
                    "value": [
                        3,
                        "String!"
                    ]
                }
            ],
            "createMetricWithTarget": [
                11,
                {
                    "cap": [
                        6
                    ],
                    "companyId": [
                        1,
                        "Int!"
                    ],
                    "fromDate": [
                        3,
                        "String!"
                    ],
                    "name": [
                        3,
                        "String!"
                    ],
                    "ownerEmail": [
                        3,
                        "String!"
                    ],
                    "reportingWindowTiming": [
                        3
                    ],
                    "slug": [
                        3
                    ],
                    "stepId": [
                        1,
                        "Int!"
                    ],
                    "target": [
                        3,
                        "String!"
                    ],
                    "toDate": [
                        3
                    ],
                    "unitDescription": [
                        3,
                        "String!"
                    ],
                    "unitDisplay": [
                        3,
                        "String!"
                    ],
                    "unitName": [
                        3,
                        "String!"
                    ],
                    "unitTargetType": [
                        3,
                        "String!"
                    ],
                    "unitType": [
                        3,
                        "String!"
                    ],
                    "unitTypeLabel": [
                        3,
                        "String!"
                    ]
                }
            ],
            "createMetricWithTargetInReview": [
                19,
                {
                    "cap": [
                        6
                    ],
                    "companyId": [
                        1,
                        "Int!"
                    ],
                    "flywheelId": [
                        1,
                        "Int!"
                    ],
                    "name": [
                        3,
                        "String!"
                    ],
                    "ownerId": [
                        1,
                        "Int!"
                    ],
                    "reportingWindowTiming": [
                        3
                    ],
                    "slug": [
                        3
                    ],
                    "stepId": [
                        1,
                        "Int!"
                    ],
                    "subgoalId": [
                        1,
                        "Int!"
                    ],
                    "target": [
                        3,
                        "String!"
                    ],
                    "unitDescription": [
                        3,
                        "String!"
                    ],
                    "unitDisplay": [
                        3,
                        "String!"
                    ],
                    "unitName": [
                        3,
                        "String!"
                    ],
                    "unitTargetType": [
                        3,
                        "String!"
                    ],
                    "unitType": [
                        3,
                        "String!"
                    ],
                    "unitTypeLabel": [
                        3,
                        "String!"
                    ]
                }
            ],
            "createOrUpdateFlywheelGoalUpdate": [
                12,
                {
                    "flywheelGoalId": [
                        1,
                        "Int!"
                    ],
                    "flywheelGoalUpdateId": [
                        1
                    ],
                    "notes": [
                        3
                    ],
                    "startDate": [
                        3,
                        "String!"
                    ],
                    "subgoalId": [
                        1
                    ],
                    "updatedBy": [
                        1,
                        "Int!"
                    ],
                    "value": [
                        3,
                        "String!"
                    ]
                }
            ],
            "createStep": [
                27,
                {
                    "flywheelId": [
                        1,
                        "Int!"
                    ],
                    "name": [
                        3,
                        "String!"
                    ],
                    "ownerId": [
                        1
                    ]
                }
            ],
            "createStepsWithMetrics": [
                11,
                {
                    "companyId": [
                        1,
                        "Int!"
                    ],
                    "completedOnboarding": [
                        1
                    ],
                    "setupComplete": [
                        1
                    ],
                    "steps": [
                        28,
                        "[StepWithMetricsArgs!]!"
                    ]
                }
            ],
            "createUser": [
                33,
                {
                    "companyId": [
                        1
                    ],
                    "email": [
                        3,
                        "String!"
                    ],
                    "firstName": [
                        3
                    ],
                    "jobTitle": [
                        3
                    ],
                    "lastName": [
                        3
                    ],
                    "role": [
                        35,
                        "UserCompanyRole!"
                    ],
                    "verified": [
                        1
                    ]
                }
            ],
            "customiseFlywheel": [
                11,
                {
                    "flywheelId": [
                        1,
                        "Int!"
                    ],
                    "name": [
                        3,
                        "String!"
                    ],
                    "steps": [
                        32,
                        "[UpdateStepAliasArg!]!"
                    ]
                }
            ],
            "deleteCompany": [
                0,
                {
                    "id": [
                        1,
                        "Int!"
                    ]
                }
            ],
            "deleteSelf": [
                3
            ],
            "deleteUserFromCompany": [
                3,
                {
                    "companyId": [
                        1,
                        "Int!"
                    ],
                    "userId": [
                        3,
                        "String!"
                    ]
                }
            ],
            "editExistingFlywheelGoalUpdate": [
                12,
                {
                    "flywheelGoalUpdateId": [
                        1,
                        "Int!"
                    ],
                    "notes": [
                        3
                    ],
                    "value": [
                        3
                    ]
                }
            ],
            "editExistingMetricUpdate": [
                19,
                {
                    "metricUpdateId": [
                        1,
                        "Int!"
                    ],
                    "notes": [
                        3
                    ],
                    "value": [
                        3
                    ]
                }
            ],
            "inviteUser": [
                33,
                {
                    "companyId": [
                        1,
                        "Int!"
                    ],
                    "email": [
                        3,
                        "String!"
                    ],
                    "firstName": [
                        3
                    ],
                    "jobTitle": [
                        3
                    ],
                    "lastName": [
                        3
                    ],
                    "role": [
                        35,
                        "UserCompanyRole!"
                    ]
                }
            ],
            "markCompanyAsOnboarded": [
                0,
                {
                    "companyId": [
                        1,
                        "Int!"
                    ]
                }
            ],
            "markPaymentPrompt": [
                0,
                {
                    "companyId": [
                        1,
                        "Int!"
                    ],
                    "shouldPrompt": [
                        13,
                        "Boolean!"
                    ]
                }
            ],
            "reviewMetric": [
                19,
                {
                    "companyId": [
                        1
                    ],
                    "disabled": [
                        13
                    ],
                    "flywheelId": [
                        1,
                        "Int!"
                    ],
                    "metricId": [
                        1,
                        "Int!"
                    ],
                    "ownerId": [
                        1
                    ],
                    "subgoalId": [
                        1,
                        "Int!"
                    ],
                    "target": [
                        3
                    ]
                }
            ],
            "setCurrentDate": [
                9,
                {
                    "currentDate": [
                        3
                    ]
                }
            ],
            "setupFlywheel": [
                11,
                {
                    "companyId": [
                        1
                    ],
                    "currency": [
                        8,
                        "Currency!"
                    ],
                    "flywheelId": [
                        1
                    ],
                    "flywheelTemplateId": [
                        1
                    ],
                    "name": [
                        3,
                        "String!"
                    ],
                    "timeframe": [
                        30,
                        "Timeframe!"
                    ],
                    "updateFrequency": [
                        31,
                        "UpdateFrequency!"
                    ]
                }
            ],
            "softDeleteFlywheel": [
                11,
                {
                    "flywheelId": [
                        1,
                        "Int!"
                    ]
                }
            ],
            "toggleActive": [
                19,
                {
                    "disabled": [
                        13
                    ],
                    "metricId": [
                        1,
                        "Int!"
                    ]
                }
            ],
            "updateCompany": [
                0,
                {
                    "companyId": [
                        1,
                        "Int!"
                    ],
                    "industryId": [
                        1
                    ],
                    "logoS3Bucket": [
                        3
                    ],
                    "logoS3Key": [
                        3
                    ],
                    "name": [
                        3
                    ]
                }
            ],
            "updateCompanyTrialExpiry": [
                0,
                {
                    "companyId": [
                        1,
                        "Int!"
                    ],
                    "trialExpiryAt": [
                        3,
                        "String!"
                    ]
                }
            ],
            "updateFlywheel": [
                11,
                {
                    "currency": [
                        8
                    ],
                    "flywheelId": [
                        1,
                        "Int!"
                    ],
                    "flywheelTemplateId": [
                        1
                    ],
                    "name": [
                        3
                    ],
                    "timeframe": [
                        30
                    ],
                    "updateFrequency": [
                        31
                    ]
                }
            ],
            "updateFlywheelGoal": [
                12,
                {
                    "flywheelGoalId": [
                        1,
                        "Int!"
                    ],
                    "goal": [
                        3
                    ],
                    "ownerId": [
                        1
                    ],
                    "updateFrequency": [
                        31
                    ]
                }
            ],
            "updateFlywheelGoalAndSubgoals": [
                12,
                {
                    "achieveBy": [
                        3,
                        "String!"
                    ],
                    "cap": [
                        6
                    ],
                    "flywheelId": [
                        1,
                        "Int!"
                    ],
                    "fromDate": [
                        3,
                        "String!"
                    ],
                    "goal": [
                        3,
                        "String!"
                    ],
                    "id": [
                        1,
                        "Int!"
                    ],
                    "name": [
                        3,
                        "String!"
                    ],
                    "subgoals": [
                        7,
                        "[CreateSubgoalArgs!]!"
                    ],
                    "toDate": [
                        3
                    ],
                    "unitDescription": [
                        3,
                        "String!"
                    ],
                    "unitDisplay": [
                        3,
                        "String!"
                    ],
                    "unitName": [
                        3,
                        "String!"
                    ],
                    "unitType": [
                        3,
                        "String!"
                    ],
                    "unitTypeLabel": [
                        3,
                        "String!"
                    ],
                    "updateFrequency": [
                        31,
                        "UpdateFrequency!"
                    ]
                }
            ],
            "updateLastSeenAt": [
                10
            ],
            "updateMetric": [
                19,
                {
                    "cap": [
                        6
                    ],
                    "companyId": [
                        1
                    ],
                    "id": [
                        1,
                        "Int!"
                    ],
                    "name": [
                        3
                    ],
                    "ownerEmail": [
                        3
                    ],
                    "reportingWindowTiming": [
                        3
                    ],
                    "target": [
                        3
                    ],
                    "unitDescription": [
                        3
                    ],
                    "unitDisplay": [
                        3
                    ],
                    "unitName": [
                        3
                    ],
                    "unitTargetType": [
                        3
                    ],
                    "unitType": [
                        3
                    ],
                    "unitTypeLabel": [
                        3
                    ]
                }
            ],
            "updateSelf": [
                33,
                {
                    "avatarS3Bucket": [
                        3
                    ],
                    "avatarS3Key": [
                        3
                    ],
                    "email": [
                        3
                    ],
                    "firstName": [
                        3
                    ],
                    "jobTitle": [
                        3
                    ],
                    "lastName": [
                        3
                    ]
                }
            ],
            "updateSubgoal": [
                29,
                {
                    "goal": [
                        3
                    ],
                    "subgoalId": [
                        1,
                        "Int!"
                    ]
                }
            ],
            "updateUser": [
                33,
                {
                    "companyId": [
                        1
                    ],
                    "role": [
                        35,
                        "UserCompanyRole!"
                    ],
                    "userId": [
                        1,
                        "Int!"
                    ]
                }
            ],
            "__typename": [
                3
            ]
        },
        "Query": {
            "getCompany": [
                0,
                {
                    "id": [
                        1,
                        "Int!"
                    ]
                }
            ],
            "getCurrentDate": [
                9
            ],
            "getFlywheel": [
                11,
                {
                    "flywheelId": [
                        1,
                        "Int!"
                    ]
                }
            ],
            "getFlywheelReview": [
                25,
                {
                    "flywheelId": [
                        1,
                        "Int!"
                    ]
                }
            ],
            "getFlywheelTemplate": [
                16,
                {
                    "flywheelId": [
                        1
                    ]
                }
            ],
            "listCompanies": [
                0
            ],
            "listFlywheels": [
                11,
                {
                    "companyId": [
                        1,
                        "Int!"
                    ]
                }
            ],
            "listIndustries": [
                17
            ],
            "listMetricsForSubgoal": [
                19,
                {
                    "flywheelId": [
                        1,
                        "Int!"
                    ],
                    "subgoalId": [
                        1,
                        "Int!"
                    ]
                }
            ],
            "listUsers": [
                33,
                {
                    "companyId": [
                        1,
                        "Int!"
                    ]
                }
            ],
            "session": [
                26
            ],
            "__typename": [
                3
            ]
        },
        "Review": {
            "flywheelGoal": [
                12
            ],
            "metrics": [
                19
            ],
            "reviewPeriod": [
                3
            ],
            "subgoal": [
                29
            ],
            "__typename": [
                3
            ]
        },
        "Session": {
            "avatarS3Bucket": [
                3
            ],
            "avatarS3Key": [
                3
            ],
            "createdAt": [
                10
            ],
            "email": [
                3
            ],
            "fakeNowDate": [
                10
            ],
            "firstName": [
                3
            ],
            "id": [
                2
            ],
            "isMetricOwner": [
                13
            ],
            "jobTitle": [
                3
            ],
            "lastName": [
                3
            ],
            "lastSeenAt": [
                10
            ],
            "role": [
                3
            ],
            "updatedAt": [
                10
            ],
            "userCompanies": [
                0
            ],
            "userFlywheels": [
                11
            ],
            "verified": [
                1
            ],
            "__typename": [
                3
            ]
        },
        "Step": {
            "alias": [
                3
            ],
            "createdAt": [
                10
            ],
            "flywheelId": [
                1
            ],
            "id": [
                2
            ],
            "metrics": [
                19
            ],
            "name": [
                3
            ],
            "order": [
                1
            ],
            "ownerId": [
                1
            ],
            "updatedAt": [
                10
            ],
            "__typename": [
                3
            ]
        },
        "StepWithMetricsArgs": {
            "alias": [
                3
            ],
            "flywheelId": [
                1
            ],
            "metrics": [
                22
            ],
            "name": [
                3
            ],
            "order": [
                1
            ],
            "__typename": [
                3
            ]
        },
        "Subgoal": {
            "createdAt": [
                10
            ],
            "endDate": [
                10
            ],
            "flywheelGoalId": [
                1
            ],
            "goal": [
                3
            ],
            "id": [
                2
            ],
            "isCheckInDue": [
                13
            ],
            "isHealthy": [
                13
            ],
            "latestProgress": [
                6
            ],
            "latestUpdate": [
                14
            ],
            "startDate": [
                10
            ],
            "updatedAt": [
                10
            ],
            "updates": [
                14
            ],
            "__typename": [
                3
            ]
        },
        "Timeframe": {},
        "UpdateFrequency": {},
        "UpdateStepAliasArg": {
            "name": [
                3
            ],
            "order": [
                1
            ],
            "stepId": [
                1
            ],
            "__typename": [
                3
            ]
        },
        "User": {
            "avatarS3Bucket": [
                3
            ],
            "avatarS3Key": [
                3
            ],
            "company": [
                0,
                {
                    "companyId": [
                        1
                    ]
                }
            ],
            "createdAt": [
                10
            ],
            "email": [
                3
            ],
            "firstName": [
                3
            ],
            "id": [
                2
            ],
            "isMetricOwner": [
                13
            ],
            "jobTitle": [
                3
            ],
            "lastName": [
                3
            ],
            "lastSeenAt": [
                10
            ],
            "role": [
                3
            ],
            "status": [
                3
            ],
            "updatedAt": [
                10
            ],
            "userCompanies": [
                0
            ],
            "userFlywheels": [
                11
            ],
            "verified": [
                1
            ],
            "__typename": [
                3
            ]
        },
        "UserCompany": {
            "id": [
                2
            ],
            "role": [
                3
            ],
            "__typename": [
                3
            ]
        },
        "UserCompanyRole": {},
        "UserMetricReview": {
            "createdAt": [
                10
            ],
            "id": [
                2
            ],
            "updatedAt": [
                10
            ],
            "user": [
                33
            ],
            "userId": [
                2
            ],
            "__typename": [
                3
            ]
        }
    }
}