import { enumCurrency } from "@roda/graphql/genql";
import {
  useEffect,
  useRef
} from "react";
import { useNavigate } from "react-router-dom";

import { OnboardingContent } from "~/components/onboarding/OnboardingContent";
import type { OnboardingStep } from "~/components/onboarding/OnboardingProgressBar";
import { Loading } from "~/components/Spinner";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useCustomiseFlywheelGoalDispatch } from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";
import { useFlywheelLoader } from "~/contexts/FlywheelLoaderContext";
import {
  useOnboardingDispatch,
  useOnboardingState
} from "~/contexts/OnboardingContext/OnboardingContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useCurrentUser } from "~/contexts/UserContext";

import type { User } from "@roda/graphql/genql";

interface OnboardingProps {
  createFlywheelMode?: boolean;
  firstStep?: OnboardingStep;
}
export const Onboarding: React.FC<OnboardingProps> = ({ createFlywheelMode }) => {
  const { user, fetching: fetchingUsers } = useCurrentUser();

  const {
    step, flywheel: onboardingFlywheel, company: onboardingCompany, user: onboardingUser, invitedUsers
  } = useOnboardingState();

  const { flywheel: selectedFlywheel, loading: fetchingFlywheels } = useSelectedFlywheel();
  const { draftFlywheels } = useFlywheelLoader();
  const onboardingDispatch = useOnboardingDispatch();
  const flywheelGoalDispatch = useCustomiseFlywheelGoalDispatch();
  const navigate = useNavigate();
  const { setHideMainSideNav } = useSideNavigation();
  const hasNavigatedBecauseOfUserFetch = useRef(false);
  const hasNavigatedBecauseOfFlywheelFetch = useRef(false);
  const { currentCompany } = useCurrentCompanyContext();
  const onboardingFlywheelForCompany = currentCompany?.id ? (onboardingFlywheel?.companyId === +currentCompany?.id ? onboardingFlywheel : null) : null;
  const selectedFlywheelForCompany = currentCompany?.id ? (!selectedFlywheel?.setupComplete ? selectedFlywheel : null) : null;
  const draftFlywheelsForCompany = currentCompany?.id ? draftFlywheels?.filter(fw => fw.companyId === +currentCompany?.id) : [];
  const flywheelToLoad = onboardingFlywheelForCompany || selectedFlywheelForCompany || draftFlywheelsForCompany?.[ 0 ] || null;

  // Initialise onboarding state using current user and flywheel context
  useEffect(() => {
    let navigateToStep: OnboardingStep = "user_details";
    const activeUsers = (currentCompany?.activeUsers || []) as User[];

    // Hide main side nav
    setHideMainSideNav(true);

    if (user?.firstName) {
      // user info setup so go to company!
      navigateToStep = "company_details";
    } else if (currentCompany?.name) {
      // company info setup so go to FW intro!
      navigateToStep = "flywheel_intro";
    } else {
      if (flywheelToLoad?.id) {
        // FW setup so go to flywheel goal!
        navigateToStep = "reporting_cycle";
      }

      if (flywheelToLoad?.latestFlywheelGoal?.achieveBy) {
        // FW setup so go to flywheel goal!
        navigateToStep = "flywheel_goal";
      }

      if (flywheelToLoad?.latestFlywheelGoal?.goal) {
        // goal setup so go to metrics & targets!
        navigateToStep = "flywheel_goal_progress";
      }

      if (flywheelToLoad?.latestFlywheelGoal?.subgoals?.length) {
        // goal setup so go to metrics & targets!
        navigateToStep = "metrics_targets";
      }

      if (onboardingUser?.email || createFlywheelMode) {
        return;
      }
    }

    if (!hasNavigatedBecauseOfUserFetch.current) {
      hasNavigatedBecauseOfUserFetch.current = true;

      onboardingDispatch({
        type: "SET_STATE",
        state: {
          step: navigateToStep,
          user: {
            firstName: user?.firstName ?? onboardingUser?.firstName ?? null,
            lastName: user?.lastName ?? onboardingUser?.lastName ?? null,
            email: user?.email ?? onboardingUser?.email ?? null,
            jobTitle: user?.jobTitle ?? onboardingUser?.jobTitle ?? null
          },
          // Always default to onboarding states for createFlywheelMode
          company: currentCompany ? {
            id: +currentCompany?.id,
            name: currentCompany?.name,
            industryId: currentCompany?.industryId,
            currency: onboardingCompany?.currency ?? enumCurrency.GBP
          } : onboardingCompany,
          invitedUsers: createFlywheelMode ? activeUsers?.map(user => user.email) : invitedUsers
        }
      });
    }
  }, [
    createFlywheelMode,
    onboardingDispatch,
    invitedUsers,
    navigate,
    onboardingCompany,
    onboardingUser,
    setHideMainSideNav,
    step,
    user,
    currentCompany
  ]);

  // Initialise onboarding flywheel using loaded-in flywheel, if present
  useEffect(() => {
    // No currency means the FW hasn't fully loaded - so we wait
    if (!flywheelToLoad?.currency) {
      return;
    }

    // We can return as we've already loaded this in!
    if (onboardingFlywheel?.id) {
      return;
    }

    if (flywheelToLoad) {
      onboardingDispatch({
        type: "LOAD",
        flywheel: flywheelToLoad
      });

      flywheelGoalDispatch({
        type: "SET_SELECTED_FLYWHEEL_TEMPLATE",
        flywheelTemplate: {
          name: flywheelToLoad?.name,
          id: flywheelToLoad?.flywheelTemplateId,
          steps: flywheelToLoad?.steps?.map((step, idx) => ({
            id: step.id,
            name: step.name,
            order: step.order || idx,
            description: "",
            metrics: step.metrics
          })) || []
        }
      });

      if (flywheelToLoad.latestFlywheelGoal) {
        flywheelGoalDispatch({
          type: "SET_SELECTED_FLYWHEEL_GOAL",
          flywheelGoal: {
            cap: flywheelToLoad.latestFlywheelGoal?.cap,
            unitDescription: flywheelToLoad.latestFlywheelGoal?.unitDescription,
            unitDisplay: flywheelToLoad.latestFlywheelGoal?.unitDisplay,
            unitName: flywheelToLoad.latestFlywheelGoal?.unitName,
            unitTypeLabel: flywheelToLoad.latestFlywheelGoal?.unitTypeLabel,
            name: flywheelToLoad.latestFlywheelGoal?.name
          }
        });
      }
    }
  }, [
    flywheelGoalDispatch,
    step,
    onboardingFlywheel,
    currentCompany,
    flywheelToLoad,
    user,
    fetchingFlywheels,
    onboardingDispatch
  ]);

  // Change step function
  const changeStep = (step: OnboardingStep) => {
    onboardingDispatch({
      type: "SET_STEP",
      step
    });
  };

  if (!hasNavigatedBecauseOfFlywheelFetch.current && (fetchingUsers || fetchingFlywheels)) {
    return (
      <Loading.AbsoluteCenter />
    );
  }

  return (
    <div className="flex flex-col gap-2 flex-1">

      <OnboardingContent
        currentStep={step}
        changeStep={changeStep}
        createFlywheelMode={createFlywheelMode}
      />

    </div>
  );
};