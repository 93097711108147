import { useTypedMutation } from "@roda/graphql/urql";

import {
  Company,
  ReadOnlyUser
} from "~/fragments/index";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

type Params = NonNullable<MutationGenqlSelection["markCompanyAsOnboarded"]>["__args"];

export const useMarkCompanyAsOnboarded = () => {
  return useTypedMutation((vars: Params) => ({
    markCompanyAsOnboarded: {
      __args: vars,
      ...Company,
      activeUsers: {
        ...ReadOnlyUser,
        lastSeenAt: true
      },
      lastSeenUser: {
        ...ReadOnlyUser,
        lastSeenAt: true
      },
      userCompany: { role: true }
    }
  }));
};