import {
  useMemo,
  useState
} from "react";
import ReactDatePicker from "react-datepicker";
import {
  useNavigate,
  useParams
} from "react-router-dom";

import { Avatar } from "~/components/Avatar";
import { Breadcrumbs } from "~/components/Breadcrumbs";
import { Button } from "~/components/Button";
import { CalloutBadge } from "~/components/CalloutBadge";
import { CompanyAvatar } from "~/components/CompanyAvatar";
import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import { Divider } from "~/components/Divider";
import { Icon } from "~/components/Icon";
import { Modal } from "~/components/Modal";
import { Loading } from "~/components/Spinner";
import { routes } from "~/constants/routes";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useDeleteCompany } from "~/hooks/company/use-delete-company";
import { useGetCompany } from "~/hooks/company/use-get-company";
import { useUpdateCompanyTrialExpiry } from "~/hooks/company/use-update-company-trial-expiry";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";
import { cn } from "~/utils/cn";
import { parseDbDate } from "~/utils/dates/parseDbDate";
import dayjs from "~/utils/dayjs";
import { truncateEmail } from "~/utils/truncateEmail";

export const CompanyDashboard = () => {
  const isMobile = useIsMobile();
  const { refetchFlywheel } = useSelectedFlywheel();
  const params = useParams() as unknown as {companyId: number};
  const { setCurrentCompany, refetchCompanies } = useCurrentCompanyContext();
  const [ { data } ] = useGetCompany(+params.companyId);
  const [ { fetching }, deleteCompanyReq ] = useDeleteCompany();
  const [ showWarning, setShowWarning ] = useState(false);
  const [ showTrialExpiryModal, setShowTrialExpiryModal ] = useState(false);
  const navigate = useNavigate();
  const { handleRodaError, assertGraphQLSuccess } = useError();
  const [ { fetching: updateCompanyFetching }, updateCompanyReq ] = useUpdateCompanyTrialExpiry();

  const handleViewFlywheel = () => {
    if (data?.getCompany) {
      setCurrentCompany(data?.getCompany);
    }

    refetchFlywheel();
    navigate(routes.dashboard(data?.getCompany?.id));
  };

  const handleDeleteCompany = () => {
    // Update company with the key
    deleteCompanyReq({ id: +params.companyId }).then(res => {
      assertGraphQLSuccess(res);

      if (res.data?.deleteCompany?.id) {
        refetchCompanies();
        navigate(routes.organisationManagement);
      }
    }).catch(error => {
      handleRodaError(error, "Failed to update company logo");

      return;
    });
  };

  const company = useMemo(() => data?.getCompany, [ data?.getCompany ]);
  const [ trialExpiryDate, setTrialExpiryDate ] = useState<Date | null>(company?.trialExpiryAt ? new Date(company?.trialExpiryAt) : null);

  if (!company) {
    return <Loading.AbsoluteCenter />;
  }

  return (
    <div className={`flex flex-col w-full flex-1 bg-white ${!isMobile && "px-10"}`}>

      <div className="flex flex-col items-center justify-start w-full flex-1">

        {/* Container for form */}
        <div className={`max-w-[1100px] flex-1 w-full text-xs sm:text-sm flex flex-col px-5 ${isMobile ? "mt-4 gap-4" : "mt-10 gap-8"}`}>

          {!isMobile && (
            <Breadcrumbs
              crumbs={[
                {
                  label: "Companies",
                  to: routes.organisationManagement
                },
                { label: company.name }
              ]}
            />
          )}

          {/* Header */}
          <div className="flex flex-row gap-2 items-center w-full">
            <div className={`font-bold text-left pb-2 ${isMobile ? "text-lg" : "text-2xl"}`}>
              Company Management
            </div>

          </div>

          <Divider />

          <section
            className=" pt-2 pb-10 space-y-6"
          >

            <div className="flex flex-row gap-2 flex-wrap items-center justify-between">
              <div className="flex items-center">
                <div className="mr-2">
                  <CompanyAvatar
                    companyName={company.name}
                    logoS3Key={company.logoS3Key ?? undefined}
                  />
                </div>

                {!!company.name && (
                  <p className="text-lg">
                    {company.name}
                  </p>
                )}

              </div>

              <div className="flex w-auto flex-wrap flex-row gap-2 text-sm">
                {company.stripeCustomerRef ? (
                  <a
                    href={`https://dashboard.stripe.com/${import.meta.env.VITE_STAGE !== "production" ? "test/" : ""}customers/${company.stripeCustomerRef}`}
                    target="_blank"
                    className="flex w-auto px-1.5 py-1 hover:bg-brand-cold-metal-200 cursor-pointer bg-brand-cold-metal-100 rounded-full text-center text-xs"
                    rel="noopener"
                  >
                    <span>
                      Subscription {company.stripeStatus}
                    </span>

                    <Icon.ChevronRightDouble className="" />
                  </a>
                ) : (
                  <div className="flex w-auto px-1.5 py-1 bg-brand-cold-metal-100 rounded-full text-center text-xs">
                    No subscription
                  </div>
                )}

                {company.trialExpiryAt && (
                  <div
                    onClick={() => {
                      setShowTrialExpiryModal(true);
                    }}
                    className="flex hover:bg-brand-cold-metal-200 cursor-pointer w-auto px-1.5 py-1 bg-brand-cold-metal-100 rounded-full text-center text-xs"
                  >
                    Trial {dayjs(company.trialExpiryAt).isAfter(dayjs()) ? `ends ${dayjs(company.trialExpiryAt).format("DD MMMM YYYY")}` : `ended ${dayjs(company.trialExpiryAt).format("DD MMMM YYYY")}`}
                  </div>
                )}

                <div className="flex w-auto px-1.5 py-1 bg-brand-cold-metal-100 rounded-full text-center text-xs">
                  Created {dayjs(company.createdAt).format("DD MMMM YYYY")}
                </div>

              </div>
            </div>

            <div className="flex flex-col  gap-2">

              <div className="flex flex-row items-center gap-2">
                <div className="font-medium text-lg my-4">
                  Users
                </div>

                <div className="flex w-auto px-1.5 py-1 bg-brand-cold-metal-100 rounded-full text-center text-xs">
                  {company.activeUserCount} active
                </div>

                {!!company.invitedUserCount && (
                  <div className="flex w-auto px-1.5 py-1 bg-brand-cold-metal-100 rounded-full text-center text-xs">
                    {company.invitedUserCount} invited
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-4">
                <div className="flex justify-between">
                  <p className="w-1/2">Name</p>

                  <p>Role</p>

                  <p>Last seen</p>
                </div>

                <Divider />

                <div className="flex flex-col gap-4 w-full overflow-auto">
                  {company.activeUsers?.length && (
                    <ul className="text-sm space-y-3 overflow-hidden">

                      {company.activeUsers?.map(user => {
                        return (
                          <li
                            key={user.id}
                            className={`flex gap-4 items-center whitespace-nowrap rounded-lg p-2 justify-between ${!user.verified ? "opacity-50" : "opacity-100"}`}
                          >
                            <div className="w-1/2 flex items-center overflow-hidden gap-x-1">
                              <Avatar
                                user={{
                                  firstName: user.firstName,
                                  lastName: user.lastName,
                                  email: user.email,
                                  avatarS3Key: user.avatarS3Key
                                }}
                                px={28}
                              />

                              {user.firstName && user.lastName && (
                                <p className="flex-1 break-all truncate">

                                  {`${user.firstName} ${user.lastName}`}
                                </p>
                              )}

                              <span className="text-brand-sub-text flex-1 break-all">{`${user.firstName && user.lastName ? truncateEmail(user.email) : user.email}${!user.verified ? " (invited)" : ""}`}</span>
                            </div>

                            <div className="flex w-auto px-1.5 py-1 bg-brand-cold-metal-100 rounded-full text-center text-xs">
                              <span>{`${parseDbDate(user?.lastSeenAt).fromNow()}`}</span>

                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-col  gap-2">
              <div className="font-medium text-sm">
                Flywheel
              </div>

              <div className="flex w-auto">
                <span>{company.counts.metricsCheckedInCount}</span>

                <span>/</span>

                <span>{company.counts.metricsTotalCount}</span>

                <span>&nbsp;metrics&nbsp;</span>

                <span>{`(${company.counts.flywheelCount} flywheel${company.counts.flywheelCount === 1 ? "" : "s"})`}</span>
              </div>

            </div>

            <div className="flex flex-wrap flex-row gap-2">

              <Button
                type="button"
                onClick={() => setShowWarning(true)}
                title="Delete"
                className=" bg-brand-error-red-400 text-brand-cold-metal-900 hover:contrast-75"
              />

              <Button
                onClick={handleViewFlywheel}
                title="Manage / View"
              />

              {!company.completedOnboarding && (
                <CalloutBadge
                  variant="info"
                >
                  This company has not completed onboarding
                </CalloutBadge>
              )}
            </div>

          </section>
        </div>

        <ConfirmationPopup
          isOpen={showWarning}
          onContinue={handleDeleteCompany}
          onCancel={() => setShowWarning(false)}
          loading={fetching}
          title="Are you sure?"
          text="This company will be deleted immediately. All related users and data attached to this entity will be permanently deleted and cannot be recovered."
          continueText="Delete company"
          iconComponent={(
            <Icon.Bin
              size={28}
              className="text-brand-cold-metal-700"
            />
          )}
        />

        {showTrialExpiryModal && (
          <Modal
            onCancel={() => setShowTrialExpiryModal(false)}
          >

            <div
              className="bg-white animate-in fade-in p-8 rounded-lg shadow-lg w-[90%] text-left z-20 max-w-[400px]"
            >

              <p className="text-lg font-semibold mb-4">Change trial expiry date</p>

              <div className="mb-8 flex flex-col gap-4">

                <p className="text-xs sm:text-base text-brand-cold-metal-500">
                  Select a new trial expiry date
                </p>

                <ReactDatePicker
                  selected={trialExpiryDate}
                  onChange={setTrialExpiryDate}
                  dateFormat="dd/MM/yyyy"
                  showIcon
                  icon={<Icon.Calendar className="self-center mt-[3px]" />}
                  className="w-[150px] cursor-pointer rounded-lg sm:text-base border items-center justify-center flex flex-row m-0 text-brand-cold-metal-500"

                />

              </div>

              {updateCompanyFetching ? (
                <div className="flex justify-center items-center">
                  <Loading.Spinner containerProps={{ className: "w-8 h-8" }} />
                </div>
              ) : (
                <div className="flex gap-5">
                  <Button
                    onClick={() => {
                      updateCompanyReq({
                        companyId: +params.companyId,
                        trialExpiryAt: trialExpiryDate ? dayjs(trialExpiryDate).format("YYYY-MM-DD") : ""
                      });
                    }}
                    title="Change trial expiry date"
                    className={cn("w-full")}
                    disabled={updateCompanyFetching}
                  />
                </div>
              )}
            </div>

          </Modal>
        )}

      </div>

    </div>
  );
};