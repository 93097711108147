import {
  UserCompanyRole,
  UserRole
} from "@roda/shared/types";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "react-use";

import { STORAGE_KEYS } from "~/constants/storageKeys";
import { useAuth } from "~/contexts/AuthContext";
import { useCurrentUser } from "~/contexts/UserContext";
import type { ListCompaniesResponse } from "~/hooks/company";
import { useListCompanies } from "~/hooks/company";
import type { GetCompanyResponse } from "~/hooks/company/use-get-company";

import type { PropsWithChildren } from "react";

interface CurrentCompanyContextProps {
  loading: boolean;
  companies: ListCompaniesResponse | null;
  currentCompany?: GetCompanyResponse | null;
  setCurrentCompany: (company: GetCompanyResponse | null) => void;
  refetchCompanies: () => void;
  isAdmin: boolean;
}

const CurrentCompanyContext = createContext<CurrentCompanyContextProps>({
  loading: false,
  companies: null,
  currentCompany: null,
  setCurrentCompany: () => null,
  refetchCompanies: () => null,
  isAdmin: false
});

export const useCurrentCompanyContext = () => useContext(CurrentCompanyContext);

export const CurrentCompanyProvider = (props: PropsWithChildren) => {
  const { user } = useCurrentUser();
  const { authenticated } = useAuth();
  const location = useLocation();
  const [ currentCompany, setCurrentCompany ] = useLocalStorage<GetCompanyResponse | null>(STORAGE_KEYS.LOCAL_STORAGE.CURRENT_COMPANY, null);

  // Extract company ID from URL path
  const getCompanyIdFromPath = useCallback((path: string) => {
    const match = path.match(/\/organisations\/(\d+)/);

    return match ? parseInt(match[ 1 ], 10) : undefined;
  }, []);

  // Fetch all of the companies - but only for Roda admins
  const [ { data, fetching }, refetch ] = useListCompanies({
    pause: !authenticated || !user || user.role !== UserRole.RODA_ADMIN,
    requestPolicy: "cache-and-network"
  });

  const userCompanies = user?.userCompanies;
  const rodaAdminCompanies = data?.listCompanies;
  const isAdmin = user?.role === UserRole.RODA_ADMIN || userCompanies?.[ 0 ]?.userCompany?.role === UserCompanyRole.ADMIN;

  useEffect(() => {
    // As long as we aren't a Roda Admin, we'll just set the first company as the current company, if there isn't one already set

    if (user?.role !== UserRole.RODA_ADMIN && userCompanies?.length) {
      if (location.pathname.includes("new")) {
        setCurrentCompany(null);
      } else {
        if (!currentCompany) {
          setCurrentCompany(userCompanies[ 0 ] as GetCompanyResponse);
        }
      }
    }

    const companyIdFromPath = getCompanyIdFromPath(location.pathname);
    const currentCompanyId = typeof currentCompany?.id === "string" ? parseInt(currentCompany.id, 10) : currentCompany?.id;

    if (companyIdFromPath && currentCompanyId !== companyIdFromPath) {
      const company = userCompanies?.find(company => {
        const companyId = typeof company.id === "string" ? parseInt(company.id, 10) : company.id;

        return companyId === companyIdFromPath;
      });

      if (company) {
        setCurrentCompany(company as GetCompanyResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?.role,
    userCompanies,
    location.pathname
  ]);

  const refetchCompanies = useCallback(() => {
    if (user?.role === UserRole.RODA_ADMIN) {
      refetch({ requestPolicy: "cache-and-network" });
    }
  }, [ refetch, user ]);

  const selectCompany = useCallback((company: GetCompanyResponse | null) => {
    setCurrentCompany(company);
  }, [ setCurrentCompany ]);

  const memoedValue = useMemo<CurrentCompanyContextProps>(
    () => ({
      loading: fetching,
      companies: user?.role === UserRole.RODA_ADMIN ? rodaAdminCompanies || [] : userCompanies || [],
      currentCompany,
      setCurrentCompany: selectCompany,
      refetchCompanies,
      isAdmin
    }),
    [
      fetching,
      user?.role,
      rodaAdminCompanies,
      userCompanies,
      currentCompany,
      selectCompany,
      refetchCompanies,
      isAdmin
    ]
  );

  return (
    <CurrentCompanyContext.Provider value={memoedValue}>
      {props.children}
    </CurrentCompanyContext.Provider>
  );
};