import {
  type Metric,
  type Step
} from "@roda/graphql/genql";
import { UserRole } from "@roda/shared/types";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  useNavigate,
  useParams
} from "react-router-dom";

import { CheckInSummaryWeekBadge } from "~/components/check-in/CheckInSummaryWeekBadge";
import { MetricCheckInPopup } from "~/components/check-in/MetricCheckInPopup";
import { MetricStatusDots } from "~/components/flywheel/roda/MetricStatusDots";
import { HealthStatus } from "~/components/flywheel/roda/types";
import { SelectInput } from "~/components/form";
import { Icon } from "~/components/Icon";
import { CheckinMetricCard } from "~/components/metric/CheckinMetricCard";
import { StepNavigation } from "~/components/StepNavigation";
import { routes } from "~/constants/routes";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useIsMobile } from "~/hooks/useIsMobile";
import { filterMetricsByWeek } from "~/utils/check-in/filterMetricsByWeek";
import dayjs from "~/utils/dayjs";
import { getArrayOfFlywheelWeeks } from "~/utils/getArrayOfFlywheelWeeks";
import { getWeekNumber } from "~/utils/getWeekNumber";

export const CheckInSummary = () => {
  // Contexts
  const { user } = useCurrentUser();
  const { setHideMainSideNav } = useSideNavigation();

  const {
    companies, currentCompany, setCurrentCompany, isAdmin
  } = useCurrentCompanyContext();

  // Hooks
  const navigate = useNavigate();
  const { companyId } = useParams();
  const isMobile = useIsMobile();

  // Flywheel related data
  const {
    flywheel, flywheelStartWeek, flywheelCycleNotStarted
  } = useSelectedFlywheel();

  /** Logic for selecting weeks and associated updates */
  // Start of the selectedWeek
  const [ selectedWeekStart, setSelectedWeekStart ] = useState(dayjs().subtract(1, "week").isoWeekday(1).format("YYYY-MM-DD")); // Weekday 1 -> Monday
  // End of the selectedWeek - SET MILLISECONDS TO 0, MYSQL ROUNDS UP TO THE NEXT DAY IF MILLISECONDS ARE 999
  const selectedWeekEnd = useMemo(() => dayjs(selectedWeekStart).isoWeekday(7).format("YYYY-MM-DD"), [ selectedWeekStart ]); // Weekday 7 -> Sunday
  // All weeks from the start of the flywheel until NOW
  const weeks = flywheel ? getArrayOfFlywheelWeeks(flywheelStartWeek, true) : [];
  // Selected step for step navigation
  const [ selectedStep, setSelectedStep ] = useState<Step | null>(null);
  // Permissions checks
  const isRodaAdmin = user?.role === UserRole.RODA_ADMIN;
  // Selected metric for check-in
  const [ selectedMetric, setSelectedMetric ] = useState<Metric | null>(null);
  const [ hasChangedStep, setHasChangedStep ] = useState(false);

  /**
   * Filter metrics based on permissions
   * Admins and Roda admins have access to check in all metrics
   * Metric owners only have access to the metrics they own
   */
  const ownedStepsWithMetrics = useMemo(() => {
    // Map the steps and filter out metrics that the user does not own
    const stepsWithFilteredMetrics = flywheel?.steps?.map(step => {
      return {
        ...step,
        metrics: ((isAdmin || isRodaAdmin) ? step.metrics : step.metrics?.filter(metric => (!metric.toDate || dayjs(metric.toDate).isAfter(dayjs())) && metric.owner?.id == user?.id)) ?? []
      };
    });

    // Then, filter out steps that have no metrics accessible to the user
    const filteredSteps = stepsWithFilteredMetrics?.filter(step => step.metrics.length > 0);

    return filteredSteps;
  }, [
    flywheel?.steps,
    isAdmin,
    isRodaAdmin,
    user?.id
  ]);

  // We need to filter all metricUpdates for each metric to get only one that is associated with the selected week ( between the selectedWeekStart and selectedWeekEnd)
  const filteredBySelectedWeek = useMemo(() => {
    return ownedStepsWithMetrics?.map(step => {
      return {
        ...step,
        metrics: step.metrics ? filterMetricsByWeek({
          metrics: step.metrics,
          weekStart: selectedWeekStart,
          weekEnd: selectedWeekEnd
        }) : []
      };
    });
  }, [
    ownedStepsWithMetrics,
    selectedWeekEnd,
    selectedWeekStart
  ]);

  // Div refs
  const stepsContainerRef = useRef<HTMLDivElement | null>(null);
  const selectedStepRef = useRef<HTMLDivElement | null>(null);
  // Steps for step navigation
  const stepNavigationSteps = useMemo(() => filteredBySelectedWeek?.filter(step => step.metrics.length), [ filteredBySelectedWeek ]) ?? [];

  // Initialise selectedStep to the first step of the owned steps array
  useEffect(() => {
    if (ownedStepsWithMetrics?.length) {
      setSelectedStep(ownedStepsWithMetrics[ 0 ]);
    }
  }, [ ownedStepsWithMetrics ]);

  /** Load in the admin company from the path param, if there isn't one loaded already or it's the wrong company */
  useEffect(() => {
    if (isRodaAdmin && (!currentCompany || (companyId && currentCompany.id !== companyId))) {
      if (!companyId || !companies?.find(company => company.id === companyId)) {
        navigate(routes.organisationManagement);
      } else {
        setCurrentCompany(companyId);
      }
    }
  }, [
    companies,
    companyId,
    currentCompany,
    isRodaAdmin,
    navigate,
    setCurrentCompany
  ]);

  /**
   * Redirect back to dashboard if user is not an admin or metric owner, or Roda admin
   */
  useEffect(() => {
    if (!isAdmin && !isRodaAdmin && !user?.isMetricOwner) {
      navigate(routes.dashboard(currentCompany ? currentCompany.id : undefined));
    }
  }, [
    companyId,
    currentCompany,
    isAdmin,
    isRodaAdmin,
    navigate,
    user?.isMetricOwner
  ]);

  // Scroll to the associated div when selectedStep changes
  useEffect(() => {
    if (selectedStep && selectedStepRef.current && hasChangedStep) {
      selectedStepRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  }, [ selectedStep, hasChangedStep ]);

  const handleChangeStep = (step: Step) => {
    setSelectedStep(step);
    setHasChangedStep(true);
  };

  // On render of this page - always show the main side nav
  useEffect(() => {
    setHideMainSideNav(false);
  }, [ setHideMainSideNav ]);

  return (
    <div className="flex flex-col max-h-container items-center h-container w-full overflow-x-hidden">

      {selectedStep && (
        <div
          className="flex flex-col self-start bg-white z-10 pt-3 md:pt-10 min-w-full sticky top-0"
          id="check-in-summary-header"
        >

          {stepNavigationSteps.length > 1 && (
            <div className="items-center">
              <div className="sm:w-[80%] mx-auto">
                <div className="overflow-x-auto w-[100dvw] sm:w-[unset]">
                  <StepNavigation
                    onClick={handleChangeStep}
                    steps={stepNavigationSteps}
                    selectedStep={selectedStep}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="w-full items-center flex flex-col">
            <div className="w-[90%] sm:w-[80%]">
              <div className={`flex ${isMobile ? "flex-col gap-y-2" : "flex-row"} justify-between md:pt-4 pb-4`}>
                {!isMobile && (
                  <div className="items-center justify-center flex">
                    <p className="font-semibold text-xl">Check-in</p>
                  </div>
                )}

                <div className="md:min-w-[30%] pt-2 md:pt-0">

                  <SelectInput
                    width="contents"
                    value={selectedWeekStart}
                    options={weeks}
                    renderOptionLabel={value => `W.${getWeekNumber({
                      date: value,
                      flywheelStartWeek
                    })} ${dayjs(value).startOf("isoWeek").format("MMMM DD")}`}
                    placeholder="Choose a week"
                    onValueChange={value => {
                      setSelectedWeekStart(value);
                    }}
                    renderOptionBadge={value => (
                      <CheckInSummaryWeekBadge
                        flywheelCycleNotStarted={flywheelCycleNotStarted}
                        metrics={filterMetricsByWeek({
                          metrics: ownedStepsWithMetrics ? ownedStepsWithMetrics.flatMap(step => step.metrics) : [],
                          weekStart: dayjs(value).startOf("isoWeek").format("YYYY-MM-DD"),
                          weekEnd: dayjs(value).endOf("isoWeek").format("YYYY-MM-DD")
                        })}
                        weekStart={dayjs(value).startOf("isoWeek")}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      )}

      {!ownedStepsWithMetrics?.length && (
        <div className="my-auto flex items-center flex-col gap-y-3 text-center sm:p-10 p-5 border-brand-cold-metal-200 border-[1px] rounded-lg">
          <Icon.BarChart className="w-7 h-7 text-brand-grey-icon-bg" />

          <p>There's nothing here for you to check-in.</p>

          <p className="text-sm">We'll notify metric owners and admins when it's time to check-in.</p>
        </div>

      )}

      <div className="w-[90%] sm:w-[80%]">
        <div
          className="flex flex-col items-center w-full gap-y-3.5 sm:gap-y-7 min-h-[400px] mb-10"
          ref={stepsContainerRef}
        >
          {isMobile && (ownedStepsWithMetrics ?? []).length >= 1 && (
            <div className="items-start w-full">
              <p className="font-semibold text-xl">Check-in</p>
            </div>
          )}

          {filteredBySelectedWeek?.map(step => (
            <div
              className="flex flex-col gap-y-4 w-full scroll-mt-check-in-summary-header-size"
              key={step.id}
              ref={stepRef => (step.id === selectedStep?.id && (selectedStepRef.current = stepRef))}
            >
              <div className="flex flex-row justify-between items-end">
                {step.metrics.length !== 0 && <p className="font-semibold">{step.alias ?? step.name}</p>}

                <MetricStatusDots
                  metricStatus={flywheelCycleNotStarted ? HealthStatus.Waiting : undefined}
                  step={step}
                />
              </div>

              <div className="flex flex-col gap-y-3">

                {step.metrics?.filter(metric => (!metric.toDate || dayjs(metric.toDate).isAfter(dayjs())))?.map(metric => (
                  <CheckinMetricCard
                    key={metric.id}
                    metric={metric}
                    step={step}
                    onClick={setSelectedMetric}
                  />
                ))}
              </div>
            </div>
          ))}

        </div>

        {selectedMetric && (
          <MetricCheckInPopup
            metric={selectedMetric}
            isOpen={!!selectedMetric}
            onClose={() => setSelectedMetric(null)}
            weekStart={selectedWeekStart}
            weekNumber={getWeekNumber({
              flywheelStartWeek,
              date: selectedWeekStart
            })}
          />
        )}

      </div>
    </div>
  );
};